/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

// ngx table css


.createuser {
    --width: 94% !important;
    --min-height: 50% important;
    margin-bottom: 50px !important;
}

.ionic-selectable-cover {
    height: 28% !important;
}

.ionic-selectable {
    height: 48px !important;
}

.action-sheet-button.sc-ion-action-sheet-md {
   // background: black;
    border: 1px solid #1bb290;
}

.row-list {
    background-color: #72bbef;
    // color:#000;
}

.row-list1 {
    background-color: #f994ba;
    // color:#000;
}

.back {
    color: #72bbef;
    // color:#000;
}
.lay {
    color: #f994ba;
    // color:#000;
}

.action-sheet-icon.sc-ion-action-sheet-md {
    color: #1bb290;
}

span.datatable-header-cell-label.draggable {
    text-transform: uppercase;
}

.page-count {
    display: none;
}

ion-icon.ion-accordion-toggle-icon.md.hydrated {
    color: #1bb290;
}

ion-modal {
    &.bottom-end {
        align-items: flex-end;
        --height: auto;

        .ion-page {
            position: relative;
            display: block;
            contain: content;
        }
    }
}
div.alert-message{
    text-align: center;
}

.my-custom-cell {
    color: green;

}

.my-custom-username {
    background-color:#fff;
    color:#000;
}
.ngx-datatable.dark .datatable-header .datatable-header-cell {
    padding: 0.5rem 0rem;
}

.my-custom-cells {
    color: red;

}

.my-custom-cellsd {
    color: green;
    border-top: 1px solid #1bb290;
}

.alert-button-group-vertical.sc-ion-alert-md {
    flex-direction: row;
    margin: 4px;
}

.row-color1 {
    color: green;
}

.empty-row {
    text-align: center;
    border-top: 1px solid #1bb290;
    padding: 10px;
    color: #1bb290;
    background: #fff;
}

.row-color2 {
    color: red;
}

.ngx-datatable.dark .datatable-footer {

    margin-top: 0px;
    border-top: 2px solid #1bb290;
}

ion-popover {
    --background: rgba(20, 21, 21, 0.748);
    --backdrop-opacity: 0.6;
    --box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.655);
    --color: white;
    --width: 93%;

}

ion-popover ion-content {
    --background: rgba(30, 31, 32, 0.852);
}

ion-popover::part(backdrop) {
    background-color: rgba(46, 46, 47, 0.896);
}

.alert-head.sc-ion-alert-md {
    text-align: center;
    color: #1bb290;

}

.alert-input.sc-ion-alert-md {
    border-bottom: none;
    border-radius: 4px;
    padding: 8px;
    border: 1px solid #1bb290;

}

ion-alert.custom-alert {
    --backdrop-opacity: 0.9;
    border: 1px solid #1bb290;
}

.custom-alert .alert-button-group {
    padding: 1px;
}

button.alert-button.alert-button-confirm {
    background-color: var(--ion-color-success);
    color: var(--ion-color-success-contrast);

}

.md button.alert-button.alert-button-confirm {
    border-radius: 4px;
}

.md .custom-alert button.alert-button {
    border: 0.55px solid rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.2)
}

.md button.alert-button.alert-button-cancel {
    color: #f4f5f8;
    background: red;
    border-radius: 4px;
    margin-bottom: 12px;

}

.alert-title.sc-ion-alert-md {
    color: #1bb290;
}

.alert-button-group.sc-ion-alert-md {
    justify-content: center;
    padding-bottom: 22px;
}
.alert-message.sc-ion-alert-md {
    color: #1bb290;
}
.md button.alert-button.alert-button-confirm {
    color: #f4f5f8;
    background: green;
    margin-bottom: 12px;
}

.historofrmae {
    height: 100vh;
}

.fltr {
    width: 77px;
}

.ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell {
    background: #fff;
    color: #000;
}

.alert-wrapper.sc-ion-alert-md {

    border: 1px solid #1bb290;
}



.alert-button.sc-ion-alert-md {
    border: 1px solid #1bb290;
    border-radius: 4px;
}

.button-row {
    white-space: nowrap;
}




.icons {
    font-size: 32px;
}
.ngx-datatable.material {
    .datatable-header {
      .datatable-header-cell {
      position: sticky;
      }
    }
  }

.fltr{
   // color:#000;
    font-weight: bold;
}
ion-segment{
    background-color: #fff;
}

ion-datetime {
    color: rgb(0, 0, 0);
}
ion-accordion-group > ion-accordion ion-item[slot=header] {
    --background: #f4f5f8;
}
ion-toolbar > toolbar-background {
    transform: none !important;
}
ion-header ion-toolbar:first-of-type {
    border-bottom: 1px solid #d7d8da;
}
td{
 width:100% !important;   
}
.note{
    text-align: center;
}


